export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return

  const { validateSignedUrl } = useAppointment()
  if (to.query.uuid && to.query.expires && to.query.signature) {
    const success = await validateSignedUrl({
      expires: to.query.expires.toString(),
      uuid: to.query.uuid.toString(),
      signature: to.query.signature.toString()
    })
    if (success) return
  }

  const notification = useNotificationStore()
  notification.$patch({
    title: 'Fehlerhafter Link',
    message: 'Der Link ist leider fehlerhaft und Sie werden auf die Startseite umgelenkt.',
    type: 'warning'
  })
  return navigateTo('/')
})
